import { Component } from 'preact';
import { Router } from 'preact-router';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalContext } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";
import { Context } from './context/context';

import Home from '../routes/home';
import AddTimer from '../routes/addTimer';
import TimerDetail from '../routes/timerDetails';
import DeleteTimer from '../routes/deleteTimer';
import UpdateTimer from '../routes/updateTimer';
import AfterRedirect from './afterRedirectSignIn';

export const pca = new PublicClientApplication(msalConfig);

export default class App extends Component {
	componentWillMount() {
		if (window.location.hostname == "localhost" && window.location.port == 8080) {
			localStorage.setItem('notech_api_url', 'https://localhost:44337');
			localStorage.setItem('notech_cdn_url', 'http://notechcdn.local')
		} else {
			localStorage.setItem('notech_cdn_url', 'https://notechcdn.w4u.site')
		}
	}

	componentDidMount() {
		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === 'visible')
				Context.getAccessToken(this.context);
		});
		window.addEventListener('focus', () => {
			Context.getAccessToken(this.context);
		});
	}

	getDateString() {
		var d = new Date();
		var datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
		return datestring;
	}

	refresh() {
		window.location.reload(true);
	}

	render({ }, { }) {
		return (
			<MsalProvider instance={pca}>
				<AuthenticatedTemplate>
					<div id="app">
						<Router>
							<Home path="/" date={this.getDateString()} />
							<Home path="/:date" />
							<AddTimer path="/add-timer" />
							<TimerDetail path="/timer/:id" />
							<DeleteTimer path="/delete-timer/:id" />
							<UpdateTimer path="/update-timer/:id" />
						</Router>
						<AfterRedirect />
						<div class=" lg:max-w-4xl m-auto relative">
							<div class="p-8 lg:px-0  text-right" >
								<a class="text-gray-500  text-xs " href="#" onClick={(e) => { e.preventDefault(); this.refresh() }}>app opnieuw laden</a>
							</div>
						</div>
					</div>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<SignIn />
				</UnauthenticatedTemplate>
			</MsalProvider>
		)
	}
}


export const SignIn = () => {
	const { instance } = useMsal();

	const handleLogin = (loginType) => {
		if (loginType === "popup") {
			localStorage.setItem("no_tech_signintype", "popup");
			instance.loginPopup(loginRequest)
				.then((loginResponse) => {
					Context.handleSignIn(loginResponse);
				})
				.catch(e => {
					console.log(e);
				});
		} else if (loginType === "redirect") {
			localStorage.setItem("no_tech_signintype", "redirect");
			instance.loginRedirect(loginRequest);
		}
	}

	return (
		<>
			<div class="fixed w-full z-50  bg-white ">
				<div class=" lg:max-w-4xl m-auto">
					<div class="w-full px-4 py-4 lg:px-0 text-xs flex items-end justify-start">
						<img class="w-24" src="/assets/img/notech_s-tr.png" alt="NoTech logo" />
					</div>
				</div>
			</div>
			<div className="min-h-full w-full flex items-center justify-center py-12  bg-zinc-100">
				<div className="lg:max-w-4xl m-auto ">
					<div className="py-12">
						<button className="hidden md:block order-1 md:order-2 justify-center w-full md:w-auto inline-flex font-bold items-center rounded-md border border-transparent bg-ntech-100 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-ntech-100 focus:outline-none focus:ring-2 focus:ring-ntech-100 focus:ring-offset-2" onClick={() => handleLogin("popup")}>Inloggen</button>
						<button className="md:hidden w-full order-1 md:order-2 justify-center w-full md:w-auto inline-flex font-bold items-center rounded-md border border-transparent bg-ntech-100 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-ntech-100 focus:outline-none focus:ring-2 focus:ring-ntech-100 focus:ring-offset-2" onClick={() => handleLogin("redirect")}>Inloggen</button>
					</div>
				</div>
			</div>
		</>
	)
}
